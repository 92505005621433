<template>
<div class="dashboard">
  <div v-if="isLoading" class="d-flex justify-content-center align-items-center" :style="{ height: '100vh' }">
    <BSpinner style="width: 3rem; height: 3rem; color: #0e41e1;" label="Large Spinner" />
  </div>

  <div v-else style="margin-top:70px; margin-bottom:60px">
    <Prices :context="context" style="margin-bottom:20px;"/>

    <b-container>
    <b-row align-h="center">
      <b-col class="mx-2" md>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col md="1" />
      <b-col class="mx-2" md>
        <b-row class="dashboard-container" align-h="center">
          <b-col class="pl-3 py-3">
            <form class="basic-form modal-body pt-0">
              <h4 class="mb-0">Compute your rewards</h4>
              <span style="font-size:12px">Beta</span>
              <b-row class="mt-4">
                <b-col cols="5" style="align-self: flex-end;">
                  <label>Network</label>
                </b-col>
                <b-col cols="7">
                 <b-form-select v-model="context" :options="options" :disabled="comparisonArray.length >= 1"></b-form-select>
               </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col cols="5" style="align-self: flex-end;">
                  <label>Amount to stake</label>
                </b-col>
                <b-col cols="7">
                  <input v-model.number="staked" type="number" list="self_wallets" :disabled="comparisonArray.length >= 1" />
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col cols="5" style="align-self: flex-end;">
                  <label>Inflation rate</label>
                </b-col>
                <b-col cols="7">
                  <input v-model.number="inflationRate" type="number" list="self_wallets" step="0.001"/>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col cols="5" style="align-self: flex-end;">
                  <label>Bonded percentage</label>
                </b-col>
                <b-col cols="7">
                  <input v-model.number="ratioBonded" type="number" list="self_wallets" step="0.01"/>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col cols="5" style="align-self: flex-end;">
                  <label>Validator</label>
                </b-col>
                <b-col cols="7">
                    <div class="buttonInside">
                      <input v-model="validator.description.moniker" type="text" list="self_wallets" />
                      <span class="inside find-icon"><unicon v-b-modal="'validator-select-modal-1'" name="search" fill="royalblue" width="15" height="15"/></span>
                    </div>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col cols="5" style="align-self: flex-end;">
                  <label>Validator commission</label>
                </b-col>
                <b-col cols="7">
                    <div class="buttonInside">
                      <input v-model="commissionRate" type="text" list="self_wallets" />
                    </div>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col cols="5" style="align-self: flex-end;">
                  <label>Compounding frequency (days)</label>
                </b-col>
                <b-col cols="7">
                  <input v-model.number="compoundingFrequency" type="number" list="self_wallets" min="1" :disabled="comparisonArray.length >= 1"/>
                </b-col>
              </b-row>
              <b-row class="mt-5 show-mobile">
                <b-col cols style="align-self: flex-end;">
                  <a v-on:click="addCompRow('')" class="link mr-3" block>Compute &amp; compare</a>
                  <a v-on:click="resetGraph" class="link" block>Reset all</a>
                </b-col>
              </b-row>
            </form>
          </b-col>
          <b-col cols="8" class="pl-0 py-5 hidden-mobile">
            <b-row >
              <b-col>
                <Plotly v-if="comparisonArray.length >= 1" :data="graphData.data" :layout="graphData.layout" :display-mode-bar="false"></Plotly>
                <Plotly v-else :layout="emptyGraphLayout" :display-mode-bar="false"></Plotly>
              </b-col>
            </b-row>
            <b-row class="mt-3" align-h="end">
              <b-col cols="6">
                <a v-on:click="addGraph()" class="link mr-3" block>Compute and compare</a>
                <a v-on:click="resetGraph" class="link" block>Reset chart</a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="1" />
    </b-row>
    </b-container>
    <b-container class="mt-3" v-if="comparisonArray.length >= 1">
      <b-row align-h="center">
        <b-col class="mx-2" md>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col md="1" />
        <b-col class="mx-2" md>
          <b-row class="dashboard-container" align-h="center">
            <b-col class="p-3">
                <b-row class="mt-1">
                  <b-col>
                    <div>
                      <b-table borderless  small
                      style="text-align:left"
                      sort-icon-left
                      :items="comparisonArray"
                      :fields="comparisonArrayFields"
                      responsive="sm">
                      <template #cell(Validator)="data">
                        <span class="hidden-mobile line" :style="{'border-color': data.item.Validator[0]} "></span>
                        {{data.item.Validator[1]}}
                      </template>
                      </b-table>
                    </div>
                  </b-col>
                </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="1" />
      </b-row>
    </b-container>
  </div>
<ValidatorSelectModal :context="context" :modalId="'validator-select-modal-1'"/>
</div>
</template>

<script>
import Prices from '@/components/prices'

import {
  Plotly
} from 'vue-plotly'
import {
  BContainer,
  // BButton,
  BFormSelect,
  BSpinner,
  BTable,
  BRow,
  BCol
} from 'bootstrap-vue'
import ValidatorSelectModal from '@/components/validatorSelect'
export default {
  components: {
    Plotly,
    BContainer,
    BFormSelect,
    BRow,
    BCol,
    BSpinner,
    BTable,
    // BButton,
    ValidatorSelectModal,
    Prices
  },
  data () {
    return {
      isLoading: true,
      mode: 'validator', // validator or general
      staked: 1000,
      // ratioBonded: {
      //   kichain: 0.4568,
      //   juno: 0.3156,
      //   lum: 0.3744
      // },
      // validator: null,
      // commissionRate: 0.05,
      // inflationRate: 0,
      compoundingFrequency: 1,
      traces: [],
      maxComp: 6,
      emptyGraphLayout: {
        margin: {
          t: 30,
          l: 50,
          r: 50,
          b: 50
        },
        yaxis: {
          hoverformat: '.0f',
          showgrid: false,
          range: [0, 10]
        },
        xaxis: {
          hoverformat: '.0f',
          showgrid: false,
          range: [0, 10]
        },
        showlegend: false,
        annotations:
        [
          {
            x: 5,
            y: 5,
            xref: 'x',
            yref: 'y',
            text: 'See your reward comparison graph here',
            showarrow: false
          }
        ]
      },
      comparisonArray: [],
      comparisonArrayFields: [{
        key: 'Validator',
        sortable: true
      },
      {
        key: 'CF',
        sortable: true
      },
      {
        key: '1W',
        sortable: true
      },
      {
        key: '1M',
        sortable: true
      },
      {
        key: '1Y',
        sortable: true
      }],
      context: 'kichain',
      options: [
        { value: 'kichain', text: 'KiChain', disabled: false },
        { value: 'juno', text: 'Juno', disabled: false },
        { value: 'lum', text: 'Lum', disabled: false }
        // { value: { C: '3PO' }, text: 'This is an option with object value' },
        // { value: 'd', text: 'This one is disabled', disabled: true }
      ],
      palette: {
        0: '#C117D3',
        1: '#4169e1',
        2: '#5BBAB4',
        3: '#C0FCF7',
        4: '#44005F',
        5: '#7283B1'
      }
    }
  },
  created () {
    // this.inflationRate =
    this.isLoading = false
  },
  computed: {
    inflationRate () {
      return this.$store.state.inflationRate[this.context]
    },
    ratioBonded () {
      return this.$store.state.bondedRate[this.context]
    },
    validator () {
      // return { description: { moniker: 'asd' }, commission: { commission_rates: { rate: 5 } } }
      return this.$store.state.validators.selectedValidator[this.context]
    },
    commissionRate: {
      // getter
      get: function () {
        return this.roundVal(this.validator.commission.commission_rates.rate, 3)
      },
      // setter
      set: function (newValue) {
        this.validator.commission.commission_rates.rate = this.roundVal(newValue, 3)
      }
    },
    graphData () {
      // const trace = this.createTrace()
      //
      // we change only the last trace, except if we have only one
      // if (this.traces.length === 0) {
      //   this.traces = [trace] // eslint-disable-line
      // } else {
      //   this.traces[this.traces.length - 1] = trace // eslint-disable-line
      // }

      const layout = {
        margin: {
          t: 30,
          l: 50,
          r: 50,
          b: 50
        },
        yaxis: {
          showgrid: true
        },
        xaxis: {
          showgrid: true
        },
        showlegend: false
      }

      return {
        data: this.traces,
        attr: {
          displayModeBar: false,
          responsive: true
        },
        layout: layout
      }
    }
  },
  methods: {
    resetGraph () {
      this.traces = []
      this.comparisonArray = []
      this.compoundingFrequency = 1
    },
    roundVal (value, dec) {
      return Math.round(value * Math.pow(10, dec)) / Math.pow(10, dec)
    },
    validateGtz () {
      this.compoundingFrequency = this.compoundingFrequency > 0 ? this.compoundingFrequency : 1
    },
    getDaysArray (startDate, endDate, step) {
      if (step <= 0) {
        return
      }
      let dates = []
      // to avoid modifying the original date
      const theDate = new Date(startDate)
      while (theDate < endDate) { // eslint-disable-line no-unmodified-loop-condition
        dates = [...dates, new Date(theDate)]
        theDate.setDate(theDate.getDate() + step)
      }
      return dates
    },
    createTrace () {
      this.validateGtz()
      const anualYield = ((1 - this.commissionRate) / this.ratioBonded) * this.inflationRate
      const periodYield = anualYield / (365 / this.compoundingFrequency)
      const startDate = new Date()
      const year = startDate.getFullYear()
      const month = startDate.getMonth()
      const day = startDate.getDate()
      const endDate = new Date(year + 3, month, day)

      const x = this.getDaysArray(startDate, endDate, this.compoundingFrequency)

      const y = []
      let cumulateStaked = this.staked

      // exp vs formula apy
      // NB: the APY depends on the frequency of compounding. The more the better. Here we try with compounding each day.
      // console.log((cumulateStaked - this.staked) / this.staked)
      // console.log(Math.pow(1 + anualYield / 365, 365) - 1)

      for (var i = 0; i < x.length; i++) {
        y.push(cumulateStaked)
        cumulateStaked += periodYield * cumulateStaked
      }

      const trace = {
        x: x,
        y: y,
        line: {
          color: this.palette[this.traces.length]
        },
        name: this.validator.description.moniker,
        type: 'scatter'
      }
      return trace
    },
    addGraph (event) {
      if (this.traces.length < this.maxComp) {
        var added = this.addCompRow(this.palette[this.traces.length])
        if (added[0]) this.traces.push(added[1])
      }
    },
    addCompRow (color) {
      var temp = [this.validator.description.moniker.replace(' ', ''), this.compoundingFrequency, this.commissionRate]
      if (this.comparisonArray.length < this.maxComp && this.checkNoDuplicate(temp)) {
        const trace = this.createTrace()
        this.comparisonArray.push(
          {
            Validator: [color, this.validator.description.moniker],
            CF: this.compoundingFrequency,
            '1W': this.roundVal(trace.y[Math.round(7 / this.compoundingFrequency)], 1),
            '1M': this.roundVal(trace.y[Math.round(30 / this.compoundingFrequency)], 1),
            '1Y': this.roundVal(trace.y[Math.round(365 / this.compoundingFrequency)], 1),
            hash: this.hashCode([this.validator.description.moniker.replace(' ', ''), this.compoundingFrequency, this.commissionRate])
          }
        )
        return [true, trace]
      }
    },
    hashCode (config) {
      var c = config.join('')
      var hash = 0
      var i
      var chr
      if (c.length === 0) return hash
      for (i = 0; i < c.length; i++) {
        chr = c.charCodeAt(i)
        hash = ((hash << 5) - hash) + chr
        hash |= 0 // Convert to 32bit integer
      }
      return hash
    },
    checkNoDuplicate (config) {
      var hashes = this.comparisonArray.map(value => value.hash)
      return !(hashes.includes(this.hashCode(config)))
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@media (max-width: 767px) {
        .hidden-mobile {
          display: none;
        }
      }
@media (min-width: 767px) {
        .show-mobile {
          display: none;
        }
      }

.line{
  width: 25px;
  height: 20px;
  border-bottom: 2px solid black;
  position: absolute;
}
</style>
