<template>
  <b-container style="">
  <b-row align-h="center">
    <b-col class="mx-2" md>
    </b-col>
  </b-row>
  <b-row align-h="center">
    <b-col md="1" />
    <b-col class="mx-2" md>
      <b-row class="dashboard-container" align-h="center">
        <b-col class="pl-3 py-3">
          <form class="basic-form modal-body pt-0">
            <!-- <h4 class="mb-0">Compute your rewards</h4> -->
            <span style="font-size:14px">{{tokens[context]}}/USD | 4h | {{ prices[0].slice(-1)[0] }}$</span>
            <b-row class="mt-4">
              <b-col style="align-self: flex-end;">
                <!-- <label>{{ prices[0] }} , {{ prices[1] }}</label>-->
              <line-chart :height="100" :chart-data="datacollection" :options="options"></line-chart>
              </b-col>
              <!-- <b-col cols="7"> -->
               <!-- <b-form-select v-model="context" :options="options" :disabled="comparisonArray.length >= 1"></b-form-select> -->
             <!-- </b-col> -->
            </b-row>
          </form>
        </b-col>
      </b-row>
    </b-col>
    <b-col md="1" />
  </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue'
import LineChart from "@/components/lineChart.js";
import {
  BContainer,
  BRow,
  BCol,
  BImg,
  BCard,
  // BCardHeader,
  BCardBody
} from 'bootstrap-vue'
export default Vue.extend({
  name: 'TopBar',
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,
    BCard,
    // BCardHeader,
    BCardBody,
    LineChart
  },
  props: {
    context: {
      type: String,
      default: 'kichain'
    }
  },
  data () {
    return {
      dark: false,
      tokens:{
        kichain: 'XKI',
        juno: "JUNO",
        lum: "LUM"
      },
      options: {
        layout: {
          padding:{
                top: 5
            }
        },
        maintainAspectRatio:false,
        legend:{
          display: false
        },
        height: "100",
        scales: {
          xAxes: [{
            gridLines: {
                display:false
            },
            ticks: {
                   display: false
            }
        }],
        yAxes: [{
            gridLines: {
                display:false
            },
            ticks: {
                display: false
            }
        }]
        }
      }
    }
  },
  computed: {
    prices () {
      return this.$store.state.prices[this.context]
    },
    datacollection () {
      return {
        labels:this.prices[1],
        datasets: [
          {
            data: this.prices[0],
            borderColor: 'rgb(65, 105, 225)',
            backgroundColor: 'rgb(65, 105, 225)',
            fill: false,
            tension: 0.5,
            pointRadius: 2
          },
        ]
      }
    }
  },
  methods: {
    selectMember () {
      return 0
    }
  }
})
</script>
<style lang="scss">
@media (max-width: 767px) {
        .hidden-mobile {
          display: none;
        }
      }
</style>
