<template>
<b-modal :id="modalId" @hidden="clear" hide-footer hide-header no-close-on-esc>
  <!-- <b-row class="pb-2">
    <b-col>
      <span>Select a validator</span>
    </b-col>
  </b-row> -->
  <b-row>
    <b-col>
      <form class="basic-form modal-body px-0" >
          <input type="text" class="mb-2" v-model="search" placeholder="Search by validator moniker">
          <a v-on:click="showAll" class="link mr-3" block>show all</a>
          <div v-if="search.length!=0" class=" mt-3" style="max-height:30rem; overflow-y:auto; overflow-x:hidden">
            <a v-for="validator in filteredValidators" v-bind:key="validator.description.moniker">
              <b-badge class="validator-badge" v-on:click="updateVal(validator), $bvModal.hide(modalId)">{{ validator.description.moniker }} | {{ roundVal(validator.commission.commission_rates.rate  * 100, 3) }}% </b-badge>
              <!-- <b-button v-on:click="updateVal(validator), $bvModal.hide(modalId)">Button</b-button> -->
            </a>
          </div>
        </form>
    </b-col>
  </b-row>
</b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BBadge
  // BButton,
  // BListGroup,
  // BListGroupItem
} from 'bootstrap-vue'
import Fuse from 'fuse.js'
import { SET_CURRENT_VALIDATOR } from '@/store/validators/mutations'
export default {
  props: {
    modalId: {
      type: String,
      default: 'validator-select-modal'
    },
    context: {
      type: String
      // ,
      // default: 'kichain'
    }
  },
  components: {
    BModal,
    BRow,
    BCol,
    BBadge
    // BButton,
    // BListGroup,
    // BListGroupItem
  },
  emits: ['update'],
  data () {
    return {
      search: ''
      // validators: []
    }
  },
  computed: {
    filteredValidators () {
      if (this.search === 'all') {
        console.log(this.validators)
        return this.validators
      }
      const options = {
        shouldSort: true,
        keys: ['description.moniker'],
        threshold: 0.2
      }
      const fuse = new Fuse(this.validators, options)
      const result = fuse.search(this.search)
      return result.map(item => item.item)
    },
    validators () {
      console.log(this.$store.state)
      return this.$store.state.validators.list[this.context]
    }
  },
  methods: {
    showAll () {
      this.search = 'all'
    },
    roundVal (value, dec) {
      return Math.round(value * Math.pow(10, dec)) / Math.pow(10, dec)
    },
    updateVal (validator) {
      console.log(validator)
      this.$store.commit(SET_CURRENT_VALIDATOR, [this.context, validator])
      // console.log(this.$store.state.validators.selectedValidator.description.moniker)
    },
    clear () {
      this.search = ''
    }
  }
}
</script>

<style scoped>
.important{
  color: var(--blueColor)
}
</style>
